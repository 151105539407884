<template>
  <div></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";

let scene, camera, renderer, exporter, mesh, mergedMesh, link;
var shapesFiles = [
  "0half_cube.glb",
  "1half_cube_v2.glb",
  "2cube.glb",
  "3quarter_cube.glb",
  "4inv_quarter_cube.glb",
];
var shapeObjects = [];
var spawnedObjects = [];
var spawnedScale = 1.0;
//
export default {
  data() {
    return {
      // testArray: [
      //   51, 51, 51, 51, 51, 51, 0, 3, 0, 51, 51, 2, 51, 2, 51, 51, 4, 2, 4, 51,
      //   51, 4, 51, 4, 51,
      // ],
      // rotationArray: [
      //   90.0, 90.0, 180.0, 90.0, 90.0, 270.0, 90.0, 90.0, 0.0, 270.0, 90.0, 0.0,
      //   180.0, 270.0, 270.0, 0.0, 180.0, 0.0, 180.0, 0.0, 180.0, 0.0, 90.0, 0.0,
      //   90.0,
      // ],
      testArray: [],
      rotationArray: [],
      shapes: [],
      rotations: [],
      type: "",
    };
  },
  mounted() {
    // this.init();
    // this.loadShapes();
    // this.animate();

    this.$store
      .dispatch("groups/handlerThreeData", {
        answer_id: this.$route.params.img_id,
      })
      .then((res) => {
        let jsonObject = res.evaluation_json;
        jsonObject.forEach((element) => {
          let testData = element.object.data;
          this.type = element.object.type;
          console.log("typee", this.type);
          testData.forEach((element) => {
            element.ids.forEach((element) => {
              this.testArray.push(element.id);
              this.rotationArray.push(element.rotation);
            });

            this.shapes.push(this.testArray);
            this.rotations.push(this.rotationArray);

            this.testArray = [];
            this.rotationArray = [];
          });
        });

        // console.log("testArray", this.testArray);
        // console.log("rotationArray", this.rotationArray);
      })
      .finally(() => {
        this.init();
        this.loadShapes();
        this.animate();
      });
  },
  methods: {
    init() {
      camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        5,
        1000
      );
      camera.position.set(0, 180, 380);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xa0a0a0);
      // scene.fog = new THREE.Fog( 0xa0a0a0, 4, 20 );

      exporter = new STLExporter();

      //

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 3);
      hemiLight.position.set(0, 20, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 20, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 2;
      directionalLight.shadow.camera.bottom = -2;
      directionalLight.shadow.camera.left = -2;
      directionalLight.shadow.camera.right = 2;
      scene.add(directionalLight);

      // ground

      const ground = new THREE.Mesh(
        new THREE.PlaneGeometry(500, 500),
        new THREE.MeshPhongMaterial({ color: 0xbbbbbb, depthWrite: false })
      );
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      scene.add(ground);

      const grid = new THREE.GridHelper(500, 50, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      //

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      document.body.appendChild(renderer.domElement);

      //

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.5, 0);
      controls.update();

      //

      window.addEventListener("resize", this.onWindowResize);

      let params = {
        exportASCII: this.exportASCII,
        exportBinary: this.exportBinary,
        modifyScale: 1.0,
        removeObjects: this.removeObjects,
        spawnObjects: this.spawnObjects,
      };

      const gui = new GUI({ width: 310 });

      gui.add(params, "exportASCII").name("Export STL (ASCII)");
      gui.add(params, "exportBinary").name("Export STL (Binary)");
      gui
        .add(params, "modifyScale", 0.01, 3, 0.01)
        .listen()
        .onChange((weight) => this.changeScale(weight))
        .name("Modify scale");
      gui.add(params, "spawnObjects").name("Spawn Objects");
      gui.add(params, "removeObjects").name("Remove Objects");
      gui.open();

      link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
    },

    changeScale(weight) {
      this.removeObjects();
      spawnedScale = weight;
      this.spawnObjects();
    },

    loadShapes() {
      const glftLoader = new GLTFLoader();
      shapesFiles.forEach((element, index) => {
        glftLoader.load("/models-3d/" + element, (gltfScene) => {
          shapeObjects.push(gltfScene);
          if (index == shapesFiles.length - 1) {
            this.spawnObjects();
          }
        });
      });
    },

    spawnObjects() {
      const material = new THREE.MeshPhongMaterial({ color: 0x777777 });
      console.log("shapes", this.shapes);
      this.shapes.forEach((row, row_index) => {
        row.forEach((element, index) => {
          if (
            element == 0 ||
            element == 1 ||
            element == 2 ||
            element == 3 ||
            element == 4
          ) {
            var tempObject =
              shapeObjects[
                element
              ].scene.children[0].children[0].children[0].geometry.clone();

            tempObject.scale(
              spawnedScale + 0.01,
              spawnedScale + 0.01,
              spawnedScale + 0.01
            );

            if (element == 0) {
              tempObject.rotateZ(
                ((this.rotations[row_index][index] + 270) / 180) * (22 / 7)
              );
            } else if (element == 1) {
              tempObject.rotateZ(
                ((this.rotations[row_index][index] + 90) / 180) * (22 / 7)
              );
            } else if (element == 3) {
              tempObject.rotateZ(
                ((this.rotations[row_index][index] + 180) / 180) * (22 / 7)
              );
            } else {
              tempObject.rotateZ(
                (this.rotations[row_index][index] / 180) * (22 / 7)
              );
            }

            if (this.type == "default") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "Xmode") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    0,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    100,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    200,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    300,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    400,
                    -100 * parseInt(index / 5)
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "cubeMode") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    0,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    100,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    200,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    300,
                    -100 * parseInt(index / 5)
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    400,
                    -100 * parseInt(index / 5)
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "verticalMode2") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "verticalMode3") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "verticalMode4") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "verticalMode5") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            }

              else if (this.type == "horizontalMode2") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "horizontalMode3") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "horizontalMode4") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "horizontalMode5") {
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            }

              else if (this.type == "creativeVerticalMode") {
              console.log("row_index", row_index);
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            } else if (this.type == "CreativeHorizontalMode") {
              console.log("row_index", row_index);
              switch (row_index) {
                case 0:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    0
                  );
                  break;
                case 1:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    100
                  );
                  break;
                case 2:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    200
                  );
                  break;
                case 3:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    300
                  );
                  break;
                case 4:
                  tempObject.translate(
                    100 * (index % 5),
                    -100 * parseInt(index / 5),
                    400
                  );
                  break;

                default:
                  break;
              }
            }

            spawnedObjects.push(tempObject);
          }
        });
      });

      var merged = BufferGeometryUtils.mergeGeometries(spawnedObjects);
      mergedMesh = new THREE.Mesh(merged, material);

      mergedMesh.position.set(-50, 135, -50);
      mergedMesh.scale.set(0.3, 0.3, 0.3);

      if (this.type == "default") {
        mergedMesh.position.set(-50, 135, -100);
        mergedMesh.scale.set(0.3, 0.3, 0.3);
      }
      if (this.type.includes("horizontalMode") || this.type === "CreativeHorizontalMode") {
        mergedMesh.position.set(-60, 15, -20);
        mergedMesh.scale.set(0.3, 0.3, 0.3);
        mergedMesh.rotateX(-Math.PI / 2);
      }
      else if (this.type == "creativeVerticalMode") {
        mergedMesh.position.set(-50, 135, -50);
        mergedMesh.scale.set(0.3, 0.3, 0.3);
      }

      scene.add(mergedMesh);
      mesh = mergedMesh;
    },


    removeObjects() {
      scene.remove(mergedMesh);
      spawnedObjects = [];
    },

    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },

    exportASCII() {
      const result = exporter.parse(mesh);
      this.saveString(result, "box.stl");
    },

    exportBinary() {
      const result = exporter.parse(mesh, { binary: true });
      this.saveArrayBuffer(result, "box.stl");
    },

    save(blob, filename) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },

    saveString(text, filename) {
      this.save(new Blob([text], { type: "text/plain" }), filename);
    },

    saveArrayBuffer(buffer, filename) {
      this.save(
        new Blob([buffer], { type: "application/octet-stream" }),
        filename
      );
    },
  },
  computed: {
    getData() {
      return this.$store.getters["groups/userDataForPrint"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
